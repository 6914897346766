import React, { useEffect, useState } from 'react'
import { Document } from '@contentful/rich-text-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { InView } from 'react-intersection-observer'
import { ContentfulRichText } from '@/atoms/ContentfulRichText'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { AngelLogo } from '@/atoms/Logos/AngelLogo'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { AsDiv, AsH1, CaptionMD, HeadingMD, HeadingSM, HeadingXS } from '@/atoms/Text'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { FaqAccordion } from '@/organisms/FaqAccordion'
import { HorizontalMediaScroller } from '@/organisms/HorizontalMediaScroller'
import { MinimalistFooter } from '@/organisms/MinimalistFooter'
import { EmailCaptureSectionCopy } from '@/services/EmailCapturePageService'
import { Faq } from '@/services/FaqService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { buildCloudinaryImagePathFromContentfulObject } from '@/utils/Cloudinary/cloudinary'
import { ScreenType, useScreenType } from '@/utils/ScreenUtils'
import { useTranslate } from '@/utils/translate/translate-client'
import { EmailCapture } from '@/views/SignUpView/EmailCapture'

const textShadowStyle = {
  textShadow: '0px 1px 0px rgba(0,0,0,0.3)',
}

export interface SignUpViewV2Props {
  heroImageCloudinaryPath: string
  watchAnywhereImageCloudinaryPath: string
  watchImages: {
    cloudinaryImagePath: string
    altText: string
  }[]
  subscriptionGroupId: string
  destination: string
  projectSlug: string
  projectName: string
  logoCloudinaryPath: string | null
  synopsisTitle?: string
  synopsis?: {
    json?: Document
  }
  buttonText?: string
  faqs: Faq[]
  heroSectionCopy: EmailCaptureSectionCopy
  midSectionCopy: EmailCaptureSectionCopy
  bottomSectionCopy: EmailCaptureSectionCopy
  sections: EmailCaptureSectionCopy[]
}

export const SignUpViewV2: React.FC<SignUpViewV2Props> = ({
  heroSectionCopy,
  heroImageCloudinaryPath,
  watchImages,
  subscriptionGroupId,
  destination,
  projectSlug,
  projectName,
  logoCloudinaryPath,
  faqs,
  synopsis,
  synopsisTitle,
  sections,
  midSectionCopy,
  bottomSectionCopy,
}) => {
  const { t } = useTranslate('sign-up')
  const [success, setSuccess] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { asPath } = useRouter()
  const [isHeroInView, setIsHeroInView] = useState<boolean>(true)
  const screenType = useScreenType()
  const pageDestination = destination === asPath ? '#browse-angel-originals' : destination
  const emailCaptureProps = {
    subscriptionGroupId,
    destination: pageDestination,
    projectSlug,
    projectName,
  }

  const canShowEmailCaptureSheet = screenType === ScreenType.mobile && !isHeroInView

  useEffect(() => {
    const hash = (asPath as string).split('#')[1]
    setSuccess(hash?.includes('success'))
    setShowModal(hash?.includes('browse-angel-originals'))
  }, [asPath])

  const defaultTagLine = t(
    'angelGuildMembersHelpFundOurShowsAndMore',
    'Angel Guild Members Help Fund Our Shows and More',
  )

  const imageSection = (
    <section className="bg-core-gray-950 py-20">
      {watchImages.length > 0 && (
        <HorizontalMediaScroller className="mb-16">
          {watchImages.map(({ cloudinaryImagePath, altText }) => {
            return (
              <div key={cloudinaryImagePath} className="relative pr-4">
                <div className="group-thumbnail relative w-full overflow-hidden rounded-lg bg-black">
                  <Image
                    className="rounded-lg"
                    src={cloudinaryImagePath}
                    alt={altText}
                    layout="responsive"
                    height={180}
                    width={320}
                  />
                </div>
                <CaptionMD className="text-center text-gray-200">{altText}</CaptionMD>
              </div>
            )
          })}
        </HorizontalMediaScroller>
      )}
    </section>
  )

  return (
    <ThemeContextProvider isDarkMode>
      <main className="relative h-[600px] w-screen md:h-[500px] lg:h-[600px]">
        <ResponsiveBackgroundImage
          preBackgroundStyles="linear-gradient(360deg, #141414 10.34%, rgba(20, 20, 20, 0) 198.73%), "
          className="h-[50vh] md:h-[40vh] lg:h-[100vh]"
          src={heroImageCloudinaryPath}
        />

        <div className="flex items-start justify-between p-6">
          <AngelLogo height={40} color="white" />
          {logoCloudinaryPath && (
            <div className="z-999 relative">
              <img
                alt={`${projectName} logo`}
                className=""
                src={getCloudinaryImageUrl({ path: logoCloudinaryPath, height: 70, format: 'webp' })}
              />
            </div>
          )}
        </div>
        <div className="absolute top-40 w-full px-4 text-center md:top-60">
          <header className="mx-auto my-8 text-center text-white sm:max-w-[475px] md:max-w-[640px] lg:max-w-[1000px]">
            <HeadingMD as={AsH1} className="lg:photon-display" weight="bold" style={textShadowStyle}>
              <div className="m-auto mb-8 max-w-md">{heroSectionCopy?.header}</div>
              <HeadingSM
                as={AsDiv}
                className="photon-title-sm md:photon-title-lg lg:photon-heading-xs mb-12 mt-2 !normal-case"
                style={textShadowStyle}
              >
                {heroSectionCopy?.subheader}
              </HeadingSM>
            </HeadingMD>
          </header>
          <PaddedContainer fluid className="text-white">
            <InView onChange={(inView) => setIsHeroInView(inView)}>
              <EmailCapture
                position="section-2"
                inputClassName="bg-black !text-gray-200 rounded-lg border border-solid border-gray-200 mr-2 !ring-gatg-pink"
                buttonClassName="!rounded-lg"
                formTagline={heroSectionCopy?.tagLine ?? defaultTagLine}
                buttonText={t('sendMeUpdates', 'Send Me Updates')}
                buttonVariant="gatg-pink"
                placeholder={t('enterYourEmail', 'Enter your email')}
                {...emailCaptureProps}
                shouldShowSuccess={success}
                shouldShowModal={showModal}
                hideFormTagLine
              />
            </InView>
          </PaddedContainer>
        </div>
      </main>
      <section className="mb-24 lg:mb-4 lg:mt-60">
        <div className="relative flex w-full items-center">
          <div
            className={classNames('flex flex-col w-full lg:flex-row items-center justify-center mx-8', {
              'lg:flex-row-reverse': midSectionCopy?.imageLocation?.[0] === 'right',
            })}
          >
            <div className="xl:w-max-3xl w-full max-w-xs md:max-w-sm lg:max-w-lg">
              <div className="ml-0 mt-8 w-auto lg:-ml-20 lg:w-[550px] xl:-ml-40 xl:w-[600px] 2xl:-ml-80 2xl:w-[750px]">
                <Image
                  layout="responsive"
                  alt="section image"
                  src={buildCloudinaryImagePathFromContentfulObject(midSectionCopy?.sectionImage)}
                  height={midSectionCopy?.sectionImage?.[0]?.height}
                  width={midSectionCopy?.sectionImage?.[0]?.width}
                />
              </div>
            </div>
            <div className="z-40 mt-4 flex basis-1/2 flex-col px-4 lg:my-36">
              <ContentfulRichText
                className="flex-1 text-white 2xl:max-w-[650px]"
                json={midSectionCopy?.sectionCopy?.json}
              />
              <div className="mb-6 max-w-[650px]">{HeroText}</div>
              {!canShowEmailCaptureSheet && (
                <EmailCapture
                  formClassName="mx-0"
                  inputClassName="bg-transparent !text-gray-200 rounded-lg border border-solid border-gray-200 mr-2 !ring-gatg-pink"
                  buttonClassName="!rounded-lg"
                  position="section-2"
                  formTagline={midSectionCopy?.tagLine ?? defaultTagLine}
                  buttonText={t('sendMeUpdates', 'Send Me Updates')}
                  buttonVariant="gatg-pink"
                  placeholder={t('enterYourEmail', 'Enter your email')}
                  {...emailCaptureProps}
                  shouldShowSuccess={success}
                  shouldShowModal={showModal}
                  hideFormTagLine
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {sections?.map((section: EmailCaptureSectionCopy) => {
        return (
          <section className="mb-24 lg:mb-4" key={section?.sys.id}>
            <div className="relative flex w-full items-center">
              {section?.backgroundImage && (
                <div className="xl:bottom-30 xl:top-18 absolute -bottom-12 top-16 w-full md:-bottom-8 md:top-24 lg:bottom-32 lg:top-28 lg:min-h-[450px]">
                  <Image
                    src={buildCloudinaryImagePathFromContentfulObject(section?.backgroundImage)}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    alt="background image"
                  />
                </div>
              )}
              <div
                className={classNames('flex flex-col w-full lg:flex-row items-center justify-center mx-8', {
                  'lg:flex-row-reverse': section?.imageLocation?.[0] === 'right',
                })}
              >
                <div
                  className={classNames(
                    '-mb-8 md:-mb-20 lg:mb-0 w-full max-w-xl md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-7xl',
                    {
                      'mx-0 lg:ml-8 lg:-mr-24': section?.imageLocation?.[0] === 'right',
                      'mx-0 lg:mr-8 lg:-ml-24': section?.imageLocation?.[0] === 'left',
                    },
                  )}
                >
                  <Image
                    layout="responsive"
                    alt="section image"
                    src={buildCloudinaryImagePathFromContentfulObject(section?.sectionImage)}
                    height={section?.sectionImage?.[0]?.height}
                    width={section?.sectionImage?.[0]?.width}
                  />
                </div>
                <div className="z-40 mt-4 flex basis-1/2 flex-col px-4 lg:my-36 lg:basis-2/3">
                  <ContentfulRichText className="flex-1 text-white" json={section?.sectionCopy?.json} />
                  {!canShowEmailCaptureSheet && (
                    <EmailCapture
                      formClassName="mx-0"
                      position="section-2"
                      inputClassName="bg-transparent !text-gray-200 rounded-lg border border-solid border-gray-200 mr-2 !ring-gatg-pink"
                      buttonClassName="!rounded-lg"
                      formTagline={section?.tagLine ?? defaultTagLine}
                      buttonText={t('sendMeUpdates', 'Send Me Updates')}
                      buttonVariant="gatg-pink"
                      placeholder={t('enterYourEmail', 'Enter your email')}
                      {...emailCaptureProps}
                      shouldShowSuccess={success}
                      shouldShowModal={showModal}
                      hideFormTagLine
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        )
      })}

      <PaddedContainer fluid className="relative mx-auto mt-8 bg-core-gray-950 text-white">
        {synopsis?.json && (
          <>
            <section role="complementary" className="relative w-full py-20 md:py-20">
              <PaddedContainer className="!max-w-[800px]">
                <HeadingXS weight="bold" className="mb-8 text-center ">
                  {synopsisTitle}
                </HeadingXS>
                <div className="flex flex-col items-center text-center">
                  <ContentfulRichText json={synopsis.json} />
                </div>
              </PaddedContainer>
            </section>
            {!watchImages?.length && imageSection}
          </>
        )}
        <section className="mt-16 bg-core-gray-950">
          <div
            className={classNames('flex flex-col w-full lg:flex-row items-center justify-center', {
              'lg:flex-row-reverse': bottomSectionCopy?.imageLocation?.[0] === 'right',
            })}
          >
            <div className="w-full max-w-xs text-center md:max-w-sm lg:mr-8 lg:max-w-md">
              <Image
                layout="responsive"
                alt="section image"
                src={buildCloudinaryImagePathFromContentfulObject(bottomSectionCopy?.sectionImage)}
                height={bottomSectionCopy?.sectionImage?.[0]?.height}
                width={bottomSectionCopy?.sectionImage?.[0]?.width}
              />
            </div>
            <div className="z-40 my-8 flex basis-1/2 flex-col items-center text-center lg:my-36 lg:items-start lg:text-left">
              {/* <ContentfulRichText className="mb-4 flex-1 text-white" json={bottomSectionCopy?.sectionCopy?.json} /> */}
              <HeadingMD
                as={AsH1}
                className="photon-heading-md md:photon-heading-lg lg:photon-heading-xl"
                weight="bold"
                style={textShadowStyle}
              >
                <div className="m-auto mb-8">{bottomSectionCopy?.subheader}</div>
                <HeadingSM
                  as={AsDiv}
                  className="photon-title-sm md:photon-title-lg lg:photon-heading-xs mb-12 mt-2 !normal-case"
                  style={textShadowStyle}
                >
                  {bottomSectionCopy?.tagLine}
                </HeadingSM>
              </HeadingMD>
              {!canShowEmailCaptureSheet && (
                <div className="w-full self-start">
                  <EmailCapture
                    position="section-2"
                    inputClassName="bg-transparent !text-gray-200 rounded-lg border border-solid border-gray-200 mr-2 !ring-gatg-pink"
                    buttonClassName="!rounded-lg"
                    formTagline={bottomSectionCopy?.tagLine ?? defaultTagLine}
                    buttonText={t('sendMeUpdates', 'Send Me Updates')}
                    buttonVariant="gatg-pink"
                    placeholder={t('enterYourEmail', 'Enter your email')}
                    formClassName="mx-0"
                    {...emailCaptureProps}
                    shouldShowSuccess={success}
                    shouldShowModal={showModal}
                    hideFormTagLine
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        <section role="complementary" className="w-full py-20 md:py-40">
          <PaddedContainer>
            <FaqAccordion faqs={faqs} />
          </PaddedContainer>
        </section>
      </PaddedContainer>

      <MinimalistFooter />
      {/* This is the mobile email capture sheet that shows after you scroll past the hero */}
      <div
        className={classNames('bg-core-gray-950 p-4 z-[99999] w-full fixed bottom-0', {
          hidden: !canShowEmailCaptureSheet,
        })}
      >
        <EmailCapture
          position="section-2"
          inputClassName="bg-transparent !text-gray-200 rounded-lg border border-solid border-gray-200 mr-2 !ring-gatg-pink"
          buttonClassName="!rounded-lg"
          formTagline={heroSectionCopy?.tagLine ?? defaultTagLine}
          buttonText={t('sendMeUpdates', 'Send Me Updates')}
          buttonVariant="gatg-pink"
          placeholder={t('enterYourEmail', 'Enter your email')}
          {...emailCaptureProps}
          shouldShowSuccess={success}
          hideFormTagLine={true}
        />
      </div>
    </ThemeContextProvider>
  )
}

const HeroText = (
  <svg width="100%" height="auto" viewBox="0 0 572 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3618_10531)">
      <path
        d="M4 78.625L13.9704 0.783691H33.9113L43.9731 78.625H28.4293L27.1615 63.2761H20.5603L19.2926 78.625H4ZM21.4168 50.6337H26.4306L23.9523 20.6323L21.4283 50.6337H21.4168Z"
        fill="#E82077"
      />
      <path
        d="M49.6846 78.625V0.783691H68.4605C76.6378 0.783691 81.8458 1.70874 84.0957 3.57027C86.3456 5.43179 87.4648 9.8629 87.4648 16.8864V24.9378C87.4648 30.5795 86.9052 34.3596 85.8088 36.2668C84.701 38.174 82.6909 39.6358 79.79 40.6637C82.5995 41.4745 84.5753 42.8107 85.7403 44.6608C86.8938 46.5223 87.4762 49.4003 87.4762 53.2946V78.6136H70.9502V51.8899C70.9502 50.2682 70.6876 49.1719 70.1508 48.6123C69.614 48.0527 68.6204 47.7672 67.1585 47.7672H66.1763V78.6136H49.696L49.6846 78.625ZM70.9388 28.2155V17.5488C70.9388 15.6188 70.7218 14.4311 70.2878 13.9971C69.8538 13.5631 68.963 13.3461 67.6153 13.3461H66.1649V33.2861C66.3476 33.2861 66.6217 33.2975 66.9644 33.3318C67.307 33.3661 67.5582 33.3775 67.7181 33.3775C68.963 33.3775 69.8196 33.1719 70.265 32.7494C70.7218 32.3268 70.9388 31.5274 70.9388 30.3397V28.2383V28.2155Z"
        fill="#E82077"
      />
      <path
        d="M93.9404 78.625V0.783691H120.106V13.3233H110.329V29.5174H120.106V42.2054H110.329V65.9827H120.106V78.625H93.9404Z"
        fill="#E82077"
      />
      <path
        d="M158.434 78.625V47.7786L147.618 0.783691H163.116L167.136 30.785L171.065 0.783691H186.7L175.702 47.7786V78.625H158.434Z"
        fill="#E82077"
      />
      <path
        d="M190.183 16.4182C190.183 9.17765 191.245 4.68944 193.369 2.94212C195.493 1.1948 200.69 0.31543 208.959 0.31543C217.227 0.31543 222.686 1.2519 224.776 3.12485C226.866 4.99779 227.917 9.4289 227.917 16.4182V62.8992C227.917 70.0483 226.855 74.548 224.731 76.398C222.606 78.2482 217.376 79.1846 209.05 79.1846C200.724 79.1846 195.379 78.2367 193.301 76.3295C191.222 74.4223 190.183 69.9455 190.183 62.8992V16.4182ZM206.663 16.8864V62.4309C206.663 63.8927 206.846 64.9206 207.2 65.4916C207.554 66.0626 208.17 66.3595 209.05 66.3595C209.827 66.3595 210.42 66.154 210.832 65.7314C211.231 65.3089 211.437 64.7264 211.437 63.9727V16.8864C211.437 15.3903 211.254 14.3739 210.877 13.8486C210.5 13.3233 209.804 13.0492 208.776 13.0492C208.091 13.0492 207.565 13.2661 207.211 13.6773C206.857 14.0998 206.674 14.6823 206.674 15.436V16.8864H206.663Z"
        fill="#E82077"
      />
      <path
        d="M234.165 0.783691H250.645V62.6137C250.645 64.0184 250.828 65.012 251.182 65.583C251.536 66.154 252.153 66.4509 253.032 66.4509C253.809 66.4509 254.403 66.2454 254.814 65.8228C255.214 65.4002 255.419 64.7836 255.419 63.9727V0.783691H271.9V63.0819C271.9 70.1626 270.849 74.6165 268.736 76.4438C266.635 78.271 261.392 79.1846 253.032 79.1846C244.672 79.1846 239.316 78.2482 237.26 76.3752C235.204 74.5023 234.176 70.0712 234.176 63.0819V0.783691H234.165Z"
        fill="#E82077"
      />
      <path
        d="M299.181 78.6255L309.151 0.78418H329.092L339.154 78.6255H323.61L322.342 63.2765H315.741L314.473 78.6255H299.181ZM316.597 50.6342H321.611L319.133 20.6328L316.609 50.6342H316.597Z"
        fill="#E82077"
      />
      <path
        d="M364.084 78.6255V0.78418H380.473V29.5179H385.338V0.78418H401.864V78.6255H385.338V42.2059H380.473V78.6255H364.084Z"
        fill="#E82077"
      />
      <path
        d="M408.603 78.6255V0.78418H434.768V13.3238H424.991V29.5179H434.768V42.2059H424.991V65.9832H434.768V78.6255H408.603Z"
        fill="#E82077"
      />
      <path
        d="M441.315 78.6255V0.78418H460.091C468.269 0.78418 473.477 1.70923 475.727 3.57076C477.976 5.43228 479.096 9.86339 479.096 16.8869V24.9383C479.096 30.58 478.536 34.3601 477.44 36.2673C476.332 38.1745 474.322 39.6363 471.421 40.6642C474.23 41.475 476.206 42.8112 477.371 44.6613C478.525 46.5228 479.107 49.4008 479.107 53.2951V78.6141H462.581V51.8904C462.581 50.2687 462.318 49.1724 461.782 48.6128C461.245 48.0532 460.251 47.7677 458.789 47.7677H457.807V78.6141H441.327L441.315 78.6255ZM462.57 28.216V17.5493C462.57 15.6193 462.353 14.4316 461.919 13.9976C461.485 13.5636 460.594 13.3466 459.246 13.3466H457.796V33.2866C457.978 33.2866 458.253 33.298 458.595 33.3323C458.938 33.3665 459.189 33.378 459.349 33.378C460.594 33.378 461.45 33.1724 461.896 32.7499C462.353 32.3273 462.57 31.5279 462.57 30.3402V28.2388V28.216Z"
        fill="#E82077"
      />
      <path
        d="M485.365 16.4182C485.365 9.17764 486.427 4.68942 488.552 2.94211C490.676 1.19479 495.872 0.31543 504.141 0.31543C512.41 0.31543 517.869 1.25189 519.959 3.12484C522.049 4.99778 523.1 9.42889 523.1 16.4182V62.8991C523.1 70.0483 522.038 74.548 519.913 76.398C517.789 78.2482 512.558 79.1846 504.233 79.1846C495.907 79.1846 490.562 78.2367 488.483 76.3295C486.405 74.4223 485.365 69.9455 485.365 62.8991V16.4182ZM501.846 16.8864V62.4309C501.846 63.8927 502.028 64.9206 502.382 65.4916C502.736 66.0626 503.353 66.3595 504.233 66.3595C505.009 66.3595 505.603 66.154 506.014 65.7314C506.414 65.3089 506.619 64.7264 506.619 63.9727V16.8864C506.619 15.3903 506.437 14.3739 506.06 13.8486C505.683 13.3232 504.986 13.0492 503.958 13.0492C503.273 13.0492 502.748 13.2661 502.394 13.6773C502.04 14.0998 501.857 14.6823 501.857 15.436V16.8864H501.846Z"
        fill="#E82077"
      />
      <path
        d="M539.603 60.8893V50.7366C539.603 47.2077 541.579 42.7194 545.553 37.2605C546.056 36.5753 546.421 36.0614 546.672 35.7188C548.545 33.0692 549.688 31.1392 550.087 29.94C550.487 28.7409 550.693 26.8565 550.693 24.2984V15.8701C550.693 15.025 550.476 14.3969 550.064 13.9743C549.642 13.5518 549.025 13.3462 548.214 13.3462C547.278 13.3462 546.615 13.6203 546.227 14.1913C545.839 14.7509 545.645 15.7559 545.645 17.1835V28.0442H529.358V16.6239C529.358 9.56606 530.432 5.08926 532.568 3.19348C534.703 1.28627 539.923 0.338379 548.226 0.338379C556.529 0.338379 561.417 1.24059 563.655 3.03359C565.882 4.82659 567.002 8.53822 567.002 14.1571V26.137C567.002 31.2191 565.003 36.6095 561.006 42.2855C560.629 42.8222 560.161 43.4732 559.601 44.2498C557.134 47.6873 555.901 51.4104 555.901 55.4417V60.9121H539.614L539.603 60.8893ZM539.603 78.6251V65.8H555.98V78.6251H539.603Z"
        fill="#E82077"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3618_10531"
        x="0"
        y="0.31543"
        width="571.002"
        height="86.8691"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3618_10531" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3618_10531" result="shape" />
      </filter>
    </defs>
  </svg>
)
