import React from 'react'
import classNames from 'classnames'
import { InternalLink } from '@/atoms/InternalLink'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { Footer } from '@/atoms/Text'
import { paths } from '@/constants/paths'
import { useThemeContext } from '@/contexts/ThemeContext'
import { ExperimentVariantPicker } from '@/organisms/Footer/ExperimentVariantPicker'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface MinimalistFooterProps {
  className?: string
  footerText?: string
}

export const MinimalistFooter: React.FC<MinimalistFooterProps> = ({ className, footerText }) => {
  const { isDarkMode } = useThemeContext()
  const { t } = useTranslate('common')
  const currentYear = new Date().getFullYear()

  return (
    <footer
      className={classNames(
        'relative z-10',
        isDarkMode ? 'bg-black text-gray-100' : 'bg-white text-gray-950',
        className,
      )}
    >
      <PaddedContainer>
        <div className="flex flex-col flex-wrap items-center justify-between gap-2 pb-16 pt-8 text-center text-gray-500 md:flex-row">
          <ExperimentVariantPicker key="experiment-variant-picker" logoColor={isDarkMode ? 'white' : 'black'} />
          <ul className="md:order-0 order-1 mt-2 flex gap-4 underline md:flex-row">
            <li>
              <InternalLink href={paths.legal.privacyPolicy} data-testid="footer-link">
                <Footer>
                  <Translate t={t} i18nKey="privacyPolicy">
                    Privacy Policy
                  </Translate>
                </Footer>
              </InternalLink>
            </li>
            <li>
              <InternalLink href={paths.legal.termsOfUse} data-testid="footer-link">
                <Footer>
                  <Translate t={t} i18nKey="termsOfUse">
                    Terms of Use
                  </Translate>
                </Footer>
              </InternalLink>
            </li>
          </ul>
          <Footer>{footerText}</Footer>
          <div className="md:order-0 order-1 max-w-[425px] text-xs">
            <p>
              <Footer>
                <Translate t={t} i18nKey="angelStudiosIsANewKindOfStudiov2">
                  Angel Studios is a new kind of movie studio - we produce and distribute award-winning titles from
                  independent creators.
                </Translate>
              </Footer>
            </p>
            <p className="mt-6 uppercase">
              <Footer>
                <Translate t={t} i18nKey="copyright" values={{ currentYear }}>
                  Copyright © {{ currentYear }} by Angel Studios, All Rights Reserved
                </Translate>
              </Footer>
            </p>
          </div>
        </div>
      </PaddedContainer>
    </footer>
  )
}
