import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useRouter } from 'next/router'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Analytics } from '@/layout/Analytics'
import { ErrorFallback, ErrorBoundary } from '@/layout/ErrorBoundary'
import { DefaultSeo } from '@/layout/Seo'
import { ToastProvider } from '@/molecules/Toast/ToastProvider'
import { getWebClient } from '@/services/ApolloClient'
import { useLocale } from '@/utils/LocaleUtil'

interface ContentOnlyLayoutProps {
  children: React.ReactNode
  isDarkMode?: boolean
}

export const ContentOnlyLayout: React.FC<ContentOnlyLayoutProps> = ({ children, isDarkMode }) => {
  const { query } = useRouter()
  const { locale } = useLocale()
  const region = Array.isArray(query.region) ? query.region[0] : query.region
  const client = getWebClient({ locale, region: region?.length === 2 ? region : undefined })

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <DefaultSeo />
      <Analytics>
        <ApolloProvider client={client}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ThemeContextProvider isDarkMode={!!isDarkMode}>
              <ToastProvider>{children}</ToastProvider>
            </ThemeContextProvider>
          </ErrorBoundary>
        </ApolloProvider>
      </Analytics>
    </ErrorBoundary>
  )
}
